<template>
  <LayoutMainPages v-if="asset">
    <h1 class="headline-large mb-s24">
      {{ $t('take_custody.title') }}
    </h1>

    <Card
      class="take-custody-card"
      v-for="(option,i) in options"
      :key="i"
    >
      <section class="flex justify-between" @click="takeCustody(option.type)">
        <h2 class="subheadline-large">
          {{ option.title }}
        </h2>

        <IconCarretRight class="take-custody-icon"/>
      </section>
    </Card>
  </LayoutMainPages>
</template>

<script>
import { mapActions } from 'vuex';
import { IconCarretRight } from '@/assets/icons';

import {
  Card,
  LayoutMainPages,
} from '@/components/misc';

export default {
  name: 'TakeCustody',

  components: {
    Card,
    IconCarretRight,
    LayoutMainPages,
  },

  created() {
    if (!this.asset) {
      this.$router.push({ name: 'holdings' });
    }
  },

  data() {
    return {
      options: [
        {
          title: this.$t('take_custody.options.ship.title'),
          to: '/take-custody/ship/',
          type: 'ship',
          active: true,
        },
        {
          title: this.$t('take_custody.options.vault.title'),
          to: '/take-custody/vault/',
          type: 'vault',
          active: true,
        }
      ]
    };
  },

  computed: {
    asset() {
      return this.$route.params.asset || JSON.parse(localStorage.getItem('takeCustodyAsset'));
    },
    
    activeOptions() {
      return this.options.filter(option => option.active);
    }
  },
  
  methods: {
    ...mapActions('ui', ['showToast']),
    
    takeCustody(type) {
      this.$router.push({
        name: 'take-custody-option', 
        params: {
          asset: this.asset,
          option: type
        }
      });
    }
  }
};
</script>

<style scoped>
.take-custody-card {
  @apply mb-s24 p-s20 cursor-pointer;
  height: 64px; 
  max-width: 600px;
  width: 100%;
}

.take-custody-icon path {
  stroke: var(--colors-text-body);
  height: 8px;
}
</style>
